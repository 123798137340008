import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import { DynamicImage, Text } from 'components';
import AppleStoreButton from './AppleStoreButton';
import GooglePlayButton from './GooglePlayButton';
import PlatformSelector from './PlatformSelector';
import { tablet, useQuery } from 'styles/breakpoints';
import Carousel from './Carousel';

export type PlatformType = 'ios' | 'android';

interface HowToSectionProps {
  title: string;
  bottomText: string;
  ios: {
    tabTitle: string;
    steps: { text: string; image: string }[];
    storeSubtitle: string;
  };
  android: {
    tabTitle: string;
    steps: { text: string; image: string }[];
    storeSubtitle: string;
  };
  subtitle: string;
}

const Container = styled.div`
  width: 100%;
  background-color: #eefdea;
  display: flex;
  justify-content: center;
  padding: 4.5rem 1rem;
  @media ${tablet} {
    padding: 3rem 1rem;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 48rem;
  width: 100%;
`;

const Heading = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 2.375rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 4rem;
  @media ${tablet} {
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }
`;

const MockupsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 4rem;
  margin-bottom: 4rem;
  width: 100%;
  @media ${tablet} {
    margin: 2rem 0;
  }
`;

const MockupBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 2rem;
`;

const Circle = styled.div`
  background: ${({ theme }) => theme.colors.primary};
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
`;

const TextCircle = styled.p`
  color: #fff;
  text-align: center;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.018rem;
  text-transform: capitalize;
`;

const MockupText = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
`;

const PlatformSpecificText = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
`;

const Divider = styled.div`
  background-color: ${({ theme }) => theme.colors.dark40};
  height: 1px;
  width: 100%;
  margin-top: 3rem;
  margin-bottom: 3rem;
`;

const BelowText = styled(Text)`
  color: #000;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
`;
const Subtitle = styled.p`
  color: #000;
  text-align: center;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.375rem;
  span {
    font-weight: 700;
  }
  margin-bottom: 1.5rem;
  @media ${tablet} {
  }
`;

const StyledImg = styled(DynamicImage)<{ isIos: boolean }>`
  width: ${({ isIos }) => (isIos ? '9.75rem' : '11.25rem')};
  height: auto;
`;

const HowToSection: FC<HowToSectionProps> = ({
  android,
  ios,
  subtitle,
  bottomText,
  title,
}) => {
  const [platform, setPlatform] = useState<PlatformType>('ios');
  const [slide, setSlide] = useState<number>(0);

  const steps = platform === 'android' ? android?.steps : ios?.steps;
  const text =
    platform === 'android' ? android?.storeSubtitle : ios?.storeSubtitle;
  const Button =
    platform === 'android' ? <GooglePlayButton /> : <AppleStoreButton />;

  const { isTablet } = useQuery();

  useEffect(() => {
    setSlide(0);
  }, [isTablet, platform]);

  const renderMockups = () =>
    steps?.map((mockup, index) => (
      <MockupBlock key={index}>
        <StyledImg
          src={mockup.image}
          alt={mockup.text}
          marginBottom="1.5rem"
          isIos={platform === 'ios'}
        />
        <TextContainer>
          <Circle>
            <TextCircle>{index + 1}</TextCircle>
          </Circle>
          <MockupText>{mockup?.text}</MockupText>
        </TextContainer>
      </MockupBlock>
    ));

  return (
    <Container>
      <ContentContainer>
        <Heading>{title}</Heading>
        <Subtitle dangerouslySetInnerHTML={{ __html: subtitle }} />
        <ButtonsContainer>
          <GooglePlayButton />
          <AppleStoreButton />
        </ButtonsContainer>
        <PlatformSelector
          iosTabTitle={ios?.tabTitle}
          androidTabTitle={android?.tabTitle}
          platform={platform}
          onSelect={setPlatform}
        />
        {isTablet ? (
          <Carousel
            slide={slide}
            onSlide={setSlide}
            slideComponents={renderMockups()}
          />
        ) : (
          <MockupsContainer>{renderMockups()}</MockupsContainer>
        )}
        <BelowText>{bottomText}</BelowText>
      </ContentContainer>
    </Container>
  );
};

export default HowToSection;

const ButtonsContainer = styled.section`
  display: flex;
  gap: 1rem;
  margin-bottom: 2.5rem;
  @media ${tablet} {
    margin-bottom: 2rem;
  }
`;
