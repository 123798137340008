import React, { Dispatch, FC, SetStateAction } from 'react';
import styled from 'styled-components';

import { Text } from 'components';
import { PlatformType } from './HowToSection';

interface PlatformSelectorProps {
  platform: PlatformType;
  onSelect: Dispatch<SetStateAction<PlatformType>>;
  iosTabTitle: string;
  androidTabTitle: string;
}

const Container = styled.div`
  width: 100%;
  max-width: 11.625rem;
  padding: 0rem 0.3125rem;
  align-items: center;
  height: 2.5rem;
  display: flex;
  flex-direction: row;
  border-radius: 0.75rem;
  background: rgba(118, 118, 128, 0.12);
`;

const PlatformSection = styled.button<{ isActive: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ isActive }) => (isActive ? '#fff' : 'unset')};
  cursor: pointer;
  border: none;
  border-radius: 0.43313rem;
  width: 5.5rem;
  height: 2rem;
`;

const PlatformSelector: FC<PlatformSelectorProps> = ({
  platform,
  onSelect,
  iosTabTitle,
  androidTabTitle,
}) => {
  const handleIOS = () => {
    onSelect('ios');
  };

  const handleAndroid = () => {
    onSelect('android');
  };

  const isAndroidAcive = platform === 'android';

  const isIOSAcive = platform === 'ios';

  return (
    <Container>
      <PlatformSection isActive={isIOSAcive} onClick={handleIOS}>
        <StyledText>{iosTabTitle}</StyledText>
      </PlatformSection>
      <PlatformSection isActive={isAndroidAcive} onClick={handleAndroid}>
        <StyledText>{androidTabTitle}</StyledText>
      </PlatformSection>
    </Container>
  );
};

export default PlatformSelector;

const StyledText = styled.p`
  color: #000;
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.25rem;
`;
