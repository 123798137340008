import React, { Dispatch, FC, SetStateAction } from 'react';
import styled from 'styled-components';

import ArrowLeft from 'assets/icons/success/arrow-left.svg';
import ArrowRight from 'assets/icons/success/arrow-right.svg';

interface CarouselProps {
  slide: number;
  onSlide: Dispatch<SetStateAction<number>>;
  slideComponents: React.ReactNode[];
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ContentContainer = styled.div`
  margin-top: 3rem;
  margin-bottom: 1.5rem;
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
`;

const IndicatorContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 auto;
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const Circle = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.light100};
  margin-left: 1rem;
  cursor: pointer;
  background-color: transparent;
`;

const IndicatorActive = styled.div<{ isLast: boolean }>`
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 50px;
  margin-right: ${({ isLast }) => (isLast ? '0rem' : '0.5rem')};
  background-color: ${({ theme }) => theme.colors.primary};
`;

const IndicatorCircle = styled.div<{ isLast: boolean }>`
  height: 0.5rem;
  width: 0.5rem;
  border-radius: 0.25rem;
  margin-right: ${({ isLast }) => (isLast ? '0rem' : '0.5rem')};
  background-color: rgba(118, 118, 128, 0.12);
`;

const Carousel: FC<CarouselProps> = ({
  slide,
  onSlide,
  slideComponents,
  text,
}) => {
  const handleNextSlide = () => {
    if (slide === slideComponents.length - 1) {
      return onSlide(0);
    }
    onSlide(slide + 1);
  };

  const handlePreviousSlide = () => {
    if (slide === 0) {
      return onSlide(slideComponents.length - 1);
    }
    onSlide(slide - 1);
  };

  const renderIndicators = () =>
    slideComponents.map((_, index) => {
      if (index === slide) {
        return (
          <IndicatorActive
            isLast={index === slideComponents.length - 1}
            key={index}
            onClick={() => onSlide(index)}
          />
        );
      }
      return (
        <IndicatorCircle
          isLast={index === slideComponents.length - 1}
          key={index}
          onClick={() => onSlide(index)}
        />
      );
    });

  return (
    <Container>
      <ContentContainer>{slideComponents[slide]}</ContentContainer>
      <BottomContainer>
        <IndicatorContainer>{renderIndicators()}</IndicatorContainer>
        {/* <ButtonsContainer>
          <Circle onClick={handlePreviousSlide}>
            <ArrowLeft />
          </Circle>
          <Circle onClick={handleNextSlide}>
            <ArrowRight />
          </Circle>
        </ButtonsContainer> */}
      </BottomContainer>
    </Container>
  );
};

export default Carousel;
