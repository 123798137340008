import React, { FC } from 'react';
import styled from 'styled-components';

import { DynamicImage, Text } from 'components';
import AppleStoreButton from './AppleStoreButton';
import GooglePlayButton from './GooglePlayButton';
import { tablet, useQuery } from 'styles/breakpoints';

interface ThankYouSectionProps {
  title: string;
  subtitle: string;
  img: string;
  alt: string;
}

const Container = styled.div`
  display: flex;
  max-width: 71.25rem;
  align-items: center;
  padding: 4.5rem 1rem;
  width: 100%;
  @media ${tablet} {
    padding: 1.5rem 1rem 3rem;
    flex-direction: column;
  }
`;

const TYTextContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
  @media ${tablet} {
    width: 100%;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  overflow: visible;
  width: 50%;
  @media ${tablet} {
    width: 100%;
    overflow: hidden;
  }
`;

const TYTitle = styled(Text)`
  margin-bottom: 1.5rem;
  width: 100%;
  @media ${tablet} {
    margin-bottom: 1rem;
    text-align: center;
  }
`;

const TYText = styled(Text)`
  margin-bottom: 3rem;
  width: 100%;
  @media ${tablet} {
    margin-bottom: 2rem;
    text-align: center;
  }
`;

const StyledDynamicImage = styled(DynamicImage)`
  max-height: 32.625rem;
  height: 100%;
  @media ${tablet} {
    max-height: 26.1875rem;
  }
`;

const TYButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media ${tablet} {
    margin-bottom: 3rem;
    justify-content: center;
  }
`;

const AppleStoreButtonStyled = styled(AppleStoreButton)`
  margin-right: 1rem;
`;

const ThankYouSection: FC<ThankYouSectionProps> = ({
  img,
  subtitle,
  title,
  alt,
}) => {
  const { isTablet } = useQuery();

  return (
    <Container>
      <TYTextContainer>
        <Title>{title}</Title>
        <Subtitle dangerouslySetInnerHTML={{ __html: subtitle }} />
        <TYButtonsContainer>
          <AppleStoreButtonStyled />
          <GooglePlayButton />
        </TYButtonsContainer>
      </TYTextContainer>
      <ImageContainer>
        <StyledDynamicImage src={img} alt={alt} />
      </ImageContainer>
    </Container>
  );
};

export default ThankYouSection;

const Title = styled.h1`
  color: #000;
  font-size: 2.375rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding-bottom: 1.5rem;
  @media ${tablet} {
    font-size: 1.5rem;
    font-weight: 900;
    line-height: 2rem;
    padding-bottom: 1rem;
    text-align: center;
    width: 100%;
  }
`;

const Subtitle = styled.p`
  color: #000;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  span {
    font-weight: 900;
  }
  padding-bottom: 3rem;
  @media ${tablet} {
    font-size: 1rem;
    line-height: 1.375rem;
    padding-bottom: 2rem;
    text-align: center;
    width: 100%;
  }
`;
