import React from 'react';
import AppleStoreLogo from 'assets/icons/apple-store-logo.svg';
import styled from 'styled-components';
import { StoresLinks } from 'utils/constants/externalLinks';

const StyledLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const AppleStoreBadge = styled(AppleStoreLogo)`
  max-width: 10.25rem;
  width: 100%;
  height: 3rem;
`;
const AppleStoreLinkBtn = ({ ...props }) => (
  <StyledLink href={StoresLinks.AppleStore} target="_blank" {...props}>
    <AppleStoreBadge />
  </StyledLink>
);

export default AppleStoreLinkBtn;
